<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :max-width="`${width}px`"
    :persistent="true"
    :hide-overlay="hideOverlay"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="true"
          @submit.prevent="() => false"
        >
          <v-container>
            <v-row>
              <v-col
                v-for="(item, index, i) in getFormScheme"
                :key="index"
                :cols="item.cols || 12"
                :sm="item.sm || ((Object.keys(formScheme).length === i + 1) ? (Object.keys(formScheme).length % 2 === 0) ? 6 : 12 : item.sm || 6)"
                :md="item.md || ((Object.keys(formScheme).length === i + 1) ? (Object.keys(formScheme).length % 2 === 0) ? 6 : 12 : item.sm || 6)"
              >
                <div v-if="item.type === 'text'">
                  <v-text-field
                    v-model="editedItem[index]"
                    :placeholder="item.placeholder || ''"
                    :label="item.label"
                    :value="item.value"
                    :prefix="item.prefix"
                    :suffix="item.suffix"
                    :readonly="item.readonly"
                    :disabled="item.disabled || (item.forCreateOnly === true && editedIndex > -1) || (item.forEditedOnly === true && editedIndex < 0 )"
                    :rules="rules(item)"
                    :clearable="item.clearable || false"
                    hide-details="auto"
                    @keyup="typeof item.changeFn === 'function' ? item.changeFn(item, $event) : changeEditedItem($event)"
                    @click="typeof item.fn === 'function' ? item.fn(item, $event) : changeEditedItem($event)"
                    @blur="checkValid"
                  />
                </div>
                <div v-else-if="item.type === 'number'">
                  <v-text-field
                    v-model="editedItem[index]"
                    type="number"
                    v-bind="item"
                    :rules="rules(item)"
                    hide-details="auto"
                    @keyup="changeEditedItem($event)"
                    @blur="checkValid"
                  />
                </div>
                <div v-else-if="item.type === 'password'">
                  <v-text-field
                    :ref="item.ref || ''"
                    v-model="editedItem[index]"
                    :append-icon="item.data.show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="item.data.show ? 'text' : 'password'"
                    :label="item.label"
                    :disabled="item.disabled"
                    :rules="rules(item)"
                    autocomplete="off"
                    hide-details="auto"
                    @click:append="item.data.show = !item.data.show"
                    @change="changeEditedItem($event)"
                    @blur="checkValid"
                  />
                </div>
                <div v-else-if="item.type === 'select'">
                  <v-select
                    v-model="editedItem[index]"
                    :clearable="item.clearable === undefined ? true : item.clearable"
                    :clear-icon="'$clear'"
                    :label="item.label"
                    :disabled="item.disabled || (item.forCreateOnly === true && editedIndex > -1) || (item.forEditedOnly === true && editedIndex < 0 )"
                    :items="item.items"
                    :multiple="item.multiple"
                    :rules="rules(item)"
                    hide-details="auto"
                    @change="changeEditedItem($event)"
                  />
                </div>
                <div v-else-if="item.type === 'autocomplete'">
                  <v-autocomplete
                    :ref="item.ref || undefined"
                    v-model="editedItem[index]"
                    :value="item.items[0]"
                    :item-text="item.itemText || 'text'"
                    :item-value="item.itemValue || 'value'"
                    :clearable="true"
                    :clear-icon="'$clear'"
                    :label="item.label"
                    :readonly="item.readonly"
                    :disabled="item.disabled || (item.forCreateOnly === true && editedIndex > -1) || (item.forEditedOnly === true && editedIndex < 0 )"
                    :items.sync="item.items"
                    :multiple="item.multiple"
                    :rules="rules(item)"
                    :cache-items="item.cacheItems"
                    :loading="item.loading"
                    :hide-no-data="item.hideNoData"
                    :hide-selected="item.hideSelected"
                    :return-object="item.returnObject"
                    :search-input.sync="item.searchItem"
                    hide-details="auto"
                    single-line
                    @update:search-input="typeof item.updateSearch === 'function' ? item.updateSearch(item, $event, editedItem[index]) : undefined"
                    @change="typeof item.changeFn === 'function' ? item.changeFn(item, $event, editedItem[index]) : changeEditedItem($event)"
                    @click="typeof item.fn === 'function' ? item.fn(item, $event) : changeEditedItem($event)"
                  />
                  <span
                    v-show="!!editedItem[index]"
                    class="red--text small"
                  >{{ item.hint }}</span>
                </div>
                <div v-else-if="item.type === 'combobox'">
                  <v-combobox
                    v-if="!item.hide"
                    :ref="item.ref || ''"
                    v-model="editedItem[index]"
                    item-text="text"
                    item-value="value"
                    :small-chips="item.smallChips"
                    :chips="item.chips"
                    :multiple="item.multiple"
                    :label="item.label"
                    :disabled="item.disabled || (item.forCreateOnly === true && editedIndex > -1) || (item.forEditedOnly === true && editedIndex < 0 )"
                    :items="item.items"
                    :rules="rules(item)"
                    :hint="item.hint"
                    :clearable="item.clearable || false"
                    :hide-selected="item.hideSelected || true"
                    :persistent-hint="!!item.hint"
                    :loading="item.loading"
                    hide-details="auto"
                    @change="typeof item.fn === 'function' ? item.fn(item, $event, editedItem[index]) : changeEditedItem($event)"
                  >
                    <template v-slot:selection="{ attrs, item: it, parent, selected }">
                      <v-chip
                        v-if="it === String(it)"
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                      >
                        <span class="pr-2">
                          {{ it }}
                        </span>
                        <v-icon
                          small
                          @click="parent.selectItem(it)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:append-outer>
                      <div v-if="item.appendIcon">
                        <table-btn
                          :icon="`${item.appendIcon}`"
                          :small="false"
                          :tooltip="!!item.appendTooltip"
                          :tooltip-text="item.appendTooltip ? `${item.appendTooltip}` : ''"
                          :fn="async (e) => item.appendClickFn(e)"
                        />
                        <span v-show="item.appendCopyBtn === true && editedItem[index] instanceof Array && editedItem[index].length > 0">
                          <table-btn
                            :icon="`content-copy`"
                            :small="false"
                            :tooltip-text="$t('dialog.copy')"
                            :fn="async () => contentCopy(editedItem[index])"
                          />
                        </span>
                      </div>
                    </template>
                  </v-combobox>
                </div>
                <div v-else-if="item.type === 'textarea'">
                  <v-textarea
                    v-model="editedItem[index]"
                    :label="item.label"
                    :rules="rules(item)"
                    :auto-grow="item['auto-grow'] || false"
                    :rows="item.rows || 5"
                    :hint="item.hint"
                    :persistent-hint="!!item.hint"
                    :hide-details="item.hint ? false : `auto`"
                    :append-icon="item.appendIcon ? `mdi-${item.appendIcon}` : ''"
                    @click:append="typeof item.appendClickFn === 'function' ? item.appendClickFn($event) : () => {}"
                    @keyup="changeEditedItem($event)"
                    @blur="checkValid"
                  />
                </div>
                <div v-else-if="item.type === 'switch'">
                  <v-switch
                    v-model="editedItem[index]"
                    v-bind="item"
                    :input-value="editedItem[index]"
                    hide-details="auto"
                    @change="changeEditedItem($event)"
                    @click="typeof item.fn === 'function' ? item.fn(item, $event, editedItem[index]) : changeEditedItem($event)"
                  />
                </div>
                <div v-else-if="item.type === 'checkbox'">
                  <v-checkbox
                    v-model="editedItem[index]"
                    :label="item.label"
                    :class="item.class"
                    :disabled="item.disabled"
                    hide-details="auto"
                    @change="changeEditedItem($event)"
                    @click="typeof item.fn === 'function' ? item.fn(item, $event, editedItem[index]) : changeEditedItem($event)"
                  />
                </div>
                <div v-else-if="item.type === 'date-picker' || item.type === 'datePicker'">
                  <v-dialog
                    ref="dialog"
                    v-model="item.modal"
                    persistent
                    width="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem[index]"
                        readonly
                        v-bind="{ ...attrs, ...item }"
                        hide-details="auto"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="editedItem[index]"
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="item.modal = false"
                      >
                        {{ $t('Close') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="typeof item.fn === 'function' ? item.fn(item, $event, editedItem[index]) : changeEditedItem($event)"
                      >
                        {{ $t('OK') }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div v-else-if="item.type === 'file'">
                  <v-file-input
                    :label="item.label"
                    :class="item.class"
                    :disabled="item.disabled || (item.forCreateOnly === true && editedIndex > -1) || (item.forEditedOnly === true && editedIndex < 0 )"
                    :accept="item.accept"
                    :inset="item.inset"
                    hide-details="auto"
                    @change="typeof item.changeFn === 'function' ? item.changeFn(item, $event) : changeEditedItem($event)"
                    @click="typeof item.fn === 'function' ? item.fn(item, $event) : changeEditedItem($event)"
                  />
                  <v-progress-linear
                    v-show="item.showProgressLine"
                    class="mt-10"
                    color="light-blue"
                    height="10"
                    :value="item.progress"
                    striped
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </div>
                <div v-else-if="item.type === 'color'">
                  <p>{{ item.label }}</p>
                  <v-btn-toggle
                    :value="editedItem[index]"
                  >
                    <v-btn
                      v-for="(color, key) of item.colors"
                      :key="key"
                      :color="color"
                      @click="(e) => $set(editedItem, index, color)"
                    >
                      <v-icon
                        v-if="editedItem[index] === color"
                        :color="color === 'black' ? 'white': 'black'"
                      >
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <div v-if="editedItem[index]">
                    <v-chip :class="`${editedItem[index]}--text mt-3`">
                      {{ editedItem[index].toUpperCase() }}
                    </v-chip>
                  </div>
                </div>
                <div v-else-if="item.type === 'component'">
                  <component
                    :is="item.component"
                    :content="editedItem[index]"
                    :item="!item.data ? editedItem : item.data"
                    @changeFn="typeof item.changeFn === 'function' ? item.changeFn(item, $event) : changeEditedItem($event)"
                  />
                </div>
                <div v-else>
                  <p>{{ $t('tableData.noDataAvailable') }}</p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ cancelText || $t('tableData.close') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="saveLoading"
          @click="save"
        >
          {{ submitText || $t('tableData.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { parseRules } from '@/utils/validator-rules.js'

export default {
  name: 'Form',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 800
    },
    formTitle: {
      type: String,
      default: ''
    },
    submitText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    formScheme: {
      type: Object,
      default: () => {
      }
    },
    formDefaultValues: {
      type: Object,
      default: () => {
      }
    },
    editedIndex: {
      type: Number,
      default: -1
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    },
    saveLoading: {
      type: Boolean,
      default: false
    },
    hideOverlay: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true
  }),
  computed: {
    getEditedItem() {
      return this.editedItem
    },
    getFormScheme () {
      return this.formScheme
    }
  },
  watch: {
    dialog() {
      this.resetForm()
    },
    editedItem(val) {
      Object.assign(this.editedItem, this.formDefaultValues)
    }
  },
  methods: {
    save() {
      this.$emit('save', this)
    },
    close() {
      this.$emit('close')
    },
    changeEditedItem (e = {}) {
      if (e !== null && e.target !== undefined && e.target.type !== 'textarea') {
        if (e.keyCode === 13) {
          return this.save()
        }
      }
      this.$emit('changeEditedItem', this.editedItem)
    },
    checkValid (e = {}) {
      const self = this
      const interval = setInterval(() => {
        self.$refs.form.validate()
        clearInterval(interval)
      }, 50)
    },
    resetForm () {
      // // const self = this
      // const interval = setInterval(async () => {
      //   await this.$refs.form.resetValidation()
      //   clearInterval(interval)
      // }, 300)
    },
    async contentCopy(_s) {
      const s = Array.isArray(_s) ? _s.join('\n') : _s
      // if (s.includes(',')) {
      //   s = s.replace(/,/g, ' ')
      // }
      await navigator.clipboard.writeText(s)
    },
    rules(item) {
      return this.editedIndex > -1
        ? parseRules(item.updateRules) || []
        : parseRules(item.createRules) || []
    },
    changeElemOfForm(key, paramKey, paramVal) {
      if (key in this.formScheme) {
        Vue.set(this.formScheme[key], paramKey, paramVal)
      }
    }
  }
}
</script>

<style scoped></style>
