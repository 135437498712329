import Vue from 'vue'

// SENTRY
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import router from './router'
import store from './store'
import i18n from './plugins/i18n'

import vuetify from './plugins/vuetify'

import './mixins'

import './plugins/event-bus'

import socket from './plugins/socket'

import './plugins/axios'
import './plugins/components'
import './plugins/toasts'
import './plugins/copy'
import './plugins/acl'
import './plugins/autocomplete-fix'
import './plugins/helper'
import './plugins/refreshData'
import './plugins/vuetify-dialogs-movable'

import './assets/css/main.css'

import App from './App.vue'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'webtools.local', 'cp.webtools.ixela.dev', 'affbuddy.space', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: false
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  socket,
  async created() {
    // Set locale
    await store.dispatch('locale/setLocale', store.getters['locale/locale'])
  },
  render: h => h(App)
}).$mount('#app')
